import React, { useState, useCallback, useMemo, useEffect } from "react";
import { toast } from 'react-toastify';
import ImportModal from "../components/popup";
import Table from "../components/Table";
import Spinner from "../components/circularProgress";
import ProductRow from "../components/manageProducts/row";
import ProductService from "../services/product.service";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder } from "../utils/appConstants";
import { capitalizeFirstLetter } from "../utils/methods";
import MultiDropdown from "../components/multiDropdown";

const ManageProducts = ({ user, actAsDomainIdMemoised, loggedInUser }) => {
  // const [loggedInUser, setLoggedInUser] = useState({})
  const defaultColumns = ['product_material_code', 'tabs_product_group', 'pidx_code', 'canonical_product_code', 'created_date'];
  const disabledColumns = ['product_material_code', 'tabs_product_group'];
  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);
  const modalTitle = 'Import'
  const [open, setOpen] = useState(false);
  const [fileKey, setFileKey] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [reload, setReload] = useState(true);

  const openModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
    setFileKey('');
  }

  const {
    order, orderBy, setOrder, setOrderBy,
  } = useSorting({ defaultOrder: SortOrder.asc, defaultOrderBy: 'shipper_id' });
  const {
    page, perPage, setPage, setPerPage,
  } = usePagination({ defaultPage: 1, defaultPerPage: 10 });

  const { data: productsRaw, loading, total } = useFetch({
    service: ProductService,
    func: 'getProducts',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order, orderBy, page, perPage,
    },
    dependency: [reload, user?.signInUserSession?.accessToken?.jwtToken, order, orderBy, page, perPage, user.attributes['custom:role']],
  });

  const transformProductData = (data) => {
    return data.map(product => ({
      ...product,
      carrier_product_description: capitalizeFirstLetter(product?.carrier_product_description),
      terminal_product_description: capitalizeFirstLetter(product?.terminal_product_description),
    }));
  };
  const products = useMemo(() => transformProductData(productsRaw || []), [productsRaw]);

  const headCells = [{
    id: 'product_material_code',
    accessor: 'product_material_code',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Product Material Code",
    width: "10%",
  }, {
    id: 'tabs_product_group',
    accessor: 'tabs_product_group',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Tabs Product Group",
    width: "10%",
  }, {
    id: 'pidx_code',
    accessor: 'pidx_code',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "PIDX Code",
    width: "10%",
  }, {
    id: 'terminal_product_code',
    accessor: 'terminal_product_code',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Terminal Product Code",
    width: "10%",
  }, {
    id: 'terminal_product_description',
    accessor: 'terminal_product_description',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Terminal Product Description",
    width: "10%",
  }, {
    id: 'season',
    accessor: 'season',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Season",
    width: "10%",
  }, {
    id: 'alt_season_product_code',
    accessor: 'alt_season_product_code',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Alt Season Product Code",
    width: "10%",
  }, {
    id: 'canonical_product_code',
    accessor: 'canonical_product_code',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Canonical Product Code",
    width: "10%",
  }, {
    id: 'canonical_product_description',
    accessor: 'canonical_product_description',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Canonical Product Description",
    width: "10%",
  }, {
    id: 'carrier_product_code',
    accessor: 'carrier_product_code',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Carrier Product Code",
    width: "10%",
  }, {
    id: 'carrier_product_description',
    accessor: 'carrier_product_description',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Carrier Product Description",
    width: "10%",
  }, {
    id: 'created_date',
    accessor: 'created_date',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Created Date",
    width: "10%",
  }, {
    id: 'last_modified',
    accessor: 'last_modified',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Last Modified",
    width: "10%",
  }];
  // useEffect(() => {
  //   getUsers(user?.signInUserSession?.accessToken?.jwtToken)
  // }, [user?.signInUserSession?.accessToken?.jwtToken])

  const exportProducts = useCallback(() => {
    setExportLoading(true)
    ProductService.exportProducts({
      token: user?.signInUserSession?.accessToken?.jwtToken,
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const exportLinkElement = document.createElement('a');

        exportLinkElement.hidden = true;
        exportLinkElement.download = `Product-Catalogue.csv`;
        exportLinkElement.href = url;
        exportLinkElement.text = "downloading...";

        document.body.appendChild(exportLinkElement);
        exportLinkElement.click();
        setExportLoading(false)
        exportLinkElement.remove();
      })
      .catch((err) => {
        toast.error(err.message || "Something went wrong", { icon: false });
        setExportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken])

  const importProducts = useCallback((file) => {
    setImportLoading(true);
    ProductService.importProducts({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      payload: {
        fileKey: fileKey
      }
    })
      .then(response => {
        toast.success("File imported successfully.", { icon: false });
        setImportLoading(false);
        setReload(prev => !prev)
        setPage(1)
        closeModal()
      })
      .catch((err) => {
        toast.error(err.response.data.message || "Something went wrong", { icon: false });
        setImportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, fileKey])


  const handleFileKeyChange = (e) => {
    setFileKey(e.target.value);
  }

  const modalContent = useMemo(() => {
    return (
      <div className="add-domain">
        <div className="form-field-box">
          <label>S3 File Key</label>
          <input value={fileKey} onChange={handleFileKeyChange} placeholder="S3 File Key" />
          <label className="d-error">{!fileKey.trim() && 'Please Enter S3 File Key'}</label>
        </div>
      </div>
    )
  }, [fileKey])

  const modalAction = useMemo(() => {
    let btnContent;

    if (importLoading) {
      btnContent = <Spinner size={22} color="white" />
    } else {
      btnContent = "Import"
    }

    return (
      <div className="add-domain-btn-div">
        <button className={`btn add-domain-btn ${!fileKey && 'disabled'}`} disabled={!fileKey} onClick={importProducts}>
          {btnContent}
        </button>
      </div>
    )
  }, [fileKey, importLoading])

  useEffect(() => {
    setPage(1)
  }, [actAsDomainIdMemoised])

  const filteredHeadCells = useMemo(() => {
    // Only include 'fetch_supplier' for SUPER_ADMIN users
    return headCells.filter(col => {
      return selectedColumns.includes(col.id);
    });
  }, [selectedColumns, loggedInUser?.role_id?.role_name]);
  const columnLabels = useMemo(() => {
    return headCells.reduce((acc, { id, label }) => {
      acc[id] = label;
      return acc;
    }, {});
  }, [headCells]);

  const renderSelectedLabels = useMemo(() => (selected) => {
    return selected.map(id => columnLabels[id])
      .map((label, index) => (index > 0 ? `, ${label}` : label))
      .join('');
  }, [columnLabels]);

  const handleChange = (event) => {
    const { target: { value } } = event;
    setSelectedColumns(value);
  }

  return (
    <div className="root">
      <div className="table-layout">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Product Catalogues</h3>
          {
            ((user.attributes['custom:role'] === "superadmin" || loggedInUser?.domain?.isProxyDomain) && !actAsDomainIdMemoised) && <div className="filter-box">
              <div className="column-selector">
                <MultiDropdown
                  headCells={headCells}
                  selectedColumns={selectedColumns}
                  disabledColumns={disabledColumns}
                  renderSelectedLabels={renderSelectedLabels}
                  handleChange={handleChange}
                />
              </div>
              {
                user.attributes['custom:role'] === "superadmin" && <>
                  <button className={`btn`} onClick={openModal}>Import
                  </button>
                  <button className={`btn`} onClick={exportProducts}>
                    {
                      exportLoading ? <Spinner size={22} color="white" /> : 'Export'
                    }
                  </button>
                </>
              }
            </div>
          }
        </div>
        <div className="users-table">
          <Table
            data={products}
            loading={loading}
            total={total}
            perPage={perPage}
            page={page}
            headCells={filteredHeadCells}
            order={order}
            orderBy={orderBy}
            setPage={setPage}
            setPerPage={setPerPage}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={ProductRow}
            noDataFoundMessage={`No Product Catalogues Found`}
          />
        </div>
      </div>
      <ImportModal
        open={open}
        title={modalTitle}
        content={modalContent}
        action={modalAction}
        onClose={closeModal}
      />
    </div>
  )
};

export default ManageProducts;