import React, { useState } from 'react';
import clsx from 'clsx';
import { TableRow, TableCell, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import { dateFormat } from "../../utils/methods";
import CopyIcon from "../../images/copy.png";
import { ORDER_STATUS, protocolVersionsList, protocolVersionV4 } from "../../utils/appConstants";
import _ from "lodash";
import CopyToClipboard from 'react-copy-to-clipboard';

const Row = ({ item, headCells, user, onReplyRowClick, isOrdersTable }) => {
  const [showJson, setShowJson] = useState(false);
  const handleJsonToggle = () => {
    setShowJson(!showJson);
  };

  const [copyState, setCopyState] = useState({
    terminal_order_details: false,
    inbound_payload_data: false,
  });

  const tooltipContent = (
    <div
      className={clsx(
        "order-details-tooltip-content-root content-special order-details-tooltip-content-root-special"
      )}
    >
      <div className="content-left content-left-block">
        <div className="input-details">
          <label className="input-details-title">Order Details</label>
          <div className="input-inner-content">
            <ul className="order-details-tooltip-content-list">
              <li className="order-details-tooltip-content-list-item">
                <p className="order-details-tooltip-content-list-item-root">
                  <label className="order-details-tooltip-content-list-item-key">
                    Order Date:{" "}
                  </label>
                  <span className="order-details-tooltip-content-list-item-value">
                    {item?.order_date ? dateFormat(item.order_date) : ""}
                  </span>
                </p>
                <p className="order-details-tooltip-content-list-item-root">
                  <label className="order-details-tooltip-content-list-item-key">
                    Shipment Date:{" "}
                  </label>
                  <span className="order-details-tooltip-content-list-item-value">
                    {item.shipment_date ? dateFormat(item.shipment_date) : ""}
                  </span>
                </p>
                <p className="order-details-tooltip-content-list-item-root">
                  <label className="order-details-tooltip-content-list-item-key">
                    Last Modified:{" "}
                  </label>
                  <span className="order-details-tooltip-content-list-item-value">
                    {item.last_modified ? dateFormat(item.last_modified) : ""}
                  </span>
                </p>
                <p className="order-details-tooltip-content-list-item-root">
                  <label className="order-details-tooltip-content-list-item-key">
                    Trailed Id:{" "}
                  </label>
                  <span className="order-details-tooltip-content-list-item-value">
                    {item?.metered_load?.trailer_id || ""}
                  </span>
                </p>
                <p className="order-details-tooltip-content-list-item-root">
                  <label className="order-details-tooltip-content-list-item-key">
                    Vehicle Id:{" "}
                  </label>
                  <span className="order-details-tooltip-content-list-item-value">
                    {item?.metered_load?.vehicle_id || ""}
                  </span>
                </p>
                <p className="order-details-tooltip-content-list-item-root">
                  <label className="order-details-tooltip-content-list-item-key">
                    Update Token:{" "}
                  </label>
                  <span className="order-details-tooltip-content-list-item-value">
                    {item.order_update_token || ""}
                  </span>
                </p>
                <p className="order-details-tooltip-content-list-item-root">
                  <label className="order-details-tooltip-content-list-item-key">
                    Created By:{" "}
                  </label>
                  <span className="order-details-tooltip-content-list-item-value">
                    {item.created_by_user ? item.created_by_user?.email : ""}
                  </span>
                </p>
                <p className="order-details-tooltip-content-list-item-root">
                  <label className="order-details-tooltip-content-list-item-key">
                    Created Domain:{" "}
                  </label>
                  <span className="order-details-tooltip-content-list-item-value">
                    {item.created_by_user
                      ? item.created_by_user?.domain_id?.carrier_alias
                      : ""}
                  </span>
                </p>
              </li>
            </ul>
            {item?.bol_number && <>
              <div className='order-details-tooltip-content-title'></div>
              <label className='order-details-tooltip-content-title'>BOL Details</label>
              <ul className='order-details-tooltip-content-list'>
                <li className='order-details-tooltip-content-list-item'>
                  <p className='order-details-tooltip-content-list-item-root'>
                    <label className='order-details-tooltip-content-list-item-key'>BOL Number: </label>
                    <span className='order-details-tooltip-content-list-item-value'>{item.bol_number || ''}</span>
                  </p>
                  <p className='order-details-tooltip-content-list-item-root'>
                    <label className='order-details-tooltip-content-list-item-key'>BOL Status: </label>
                    <span className='order-details-tooltip-content-list-item-value'>{item.bol_status || ''}</span>
                  </p>
                </li>
              </ul>
            </>}
            <div className="order-details-tooltip-content-title"></div>
            <label className="order-details-tooltip-content-title">
              Line Items
            </label>
            <ul className="order-details-tooltip-content-list">
              {item.metered_load && item.metered_load.line_items
                ? item.metered_load.line_items.map((lineItem, index) => {
                  return (
                    <li
                      className="order-details-tooltip-content-list-item"
                      key={index}
                    >
                      <p className="order-details-tooltip-content-list-item-root">
                        <label className="order-details-tooltip-content-list-item-key">
                          Line Number:{" "}
                        </label>
                        <span className="order-details-tooltip-content-list-item-value">
                          {lineItem.line_item_number}
                        </span>
                      </p>
                      <p className="order-details-tooltip-content-list-item-root">
                        <label className="order-details-tooltip-content-list-item-key">
                          Product Code:{" "}
                        </label>
                        <span className="order-details-tooltip-content-list-item-value">
                          {lineItem.product_code}
                        </span>
                      </p>
                      <p className="order-details-tooltip-content-list-item-root">
                        <label className="order-details-tooltip-content-list-item-key">
                          Product Quantity:{" "}
                        </label>
                        <span className="order-details-tooltip-content-list-item-value">
                          {lineItem.product_quantity}
                        </span>
                      </p>
                    </li>
                  );
                })
                : null}
            </ul>
          </div>
        </div>
      </div>
      <div className="break-line"></div>
      <div className="content-right content-right-block">
        <div className="input-details">
          <div className="ouput-details-block">
            <div>
              <label className="input-details-title">Output Details</label>
            </div>
            {user &&
              user?.attributes["custom:role"] === "superadmin" &&
              (item?.inbound_payload_data || item?.terminal_order_details) && (
                <div className="json-toggle">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showJson}
                        onChange={handleJsonToggle}
                      />
                    }
                    label={showJson ? "Hide JSON" : "Show JSON"}
                  />
                </div>
              )}
          </div>

          {item?.translated_data ? (
            <div className="input-inner-content">
              {protocolVersionsList.includes(
                item?.metered_load?.protocol_version
              ) &&
                item?.translated_data?.split_load?.success === false && (
                  <div className="content-main-block">
                    <label className="order-details-tooltip-content-title">
                      Split Load
                    </label>
                    <ul className="order-details-tooltip-content-list">
                      <li className="order-details-tooltip-content-list-item">
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            From:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value">
                            {item?.translated_data?.split_load?.from === true
                              ? "true"
                              : item?.translated_data?.split_load?.from ===
                                false
                                ? "false"
                                : "-"}
                          </span>
                        </p>
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            To:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value">
                            {item?.translated_data?.split_load?.to ?? "-"}
                          </span>
                        </p>
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            Success:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value error">
                            {item?.translated_data?.split_load?.success === true
                              ? "True"
                              : `${String(item?.translated_data?.split_load?.success ?? "")}`}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                )}
              {protocolVersionsList.includes(
                item?.metered_load?.protocol_version
              ) &&
                item?.translated_data?.expiration_date?.success === false && (
                  <div className="content-main-block">
                    <label className="order-details-tooltip-content-title">
                      Expiration Date
                    </label>
                    :
                    <ul className="order-details-tooltip-content-list">
                      <li className="order-details-tooltip-content-list-item">
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            From:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value">
                            {item?.translated_data?.expiration_date?.from ?
                              dateFormat(item?.translated_data?.expiration_date?.from): "-"}
                          </span>
                        </p>
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            To:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value">
                            {item?.translated_data?.expiration_date?.to ?? "-"}
                          </span>
                        </p>
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            Success:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value error">
                            {item?.translated_data?.expiration_date?.success ===
                              true
                              ? "True"
                              : `${String(item?.translated_data?.expiration_date?.success ?? "")}`}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                )}
              <div className="content-main-block">
                <label className="order-details-tooltip-content-title">
                  {protocolVersionsList.includes(
                    item?.metered_load?.protocol_version
                  )
                    ? protocolVersionV4 === item?.metered_load?.protocol_version ? "Tractor" : 'Primary Trailer'
                    : "Driver Id"}
                </label>
                <ul className="order-details-tooltip-content-list">
                  <li className="order-details-tooltip-content-list-item">
                    <p className="order-details-tooltip-content-list-item-root">
                      <label className="order-details-tooltip-content-list-item-key">
                        From:{" "}
                      </label>
                      <span className="order-details-tooltip-content-list-item-value">
                        {protocolVersionsList.includes(
                          item?.metered_load?.protocol_version
                        )
                          ? protocolVersionV4 === item?.metered_load?.protocol_version ? item?.translated_data?.tractor?.from : item?.translated_data?.primary_trailer?.from
                          : item?.translated_data?.driver_id?.from ?? "-"}
                      </span>
                    </p>
                    <p className="order-details-tooltip-content-list-item-root">
                      <label className="order-details-tooltip-content-list-item-key">
                        To:{" "}
                      </label>
                      <span className="order-details-tooltip-content-list-item-value">
                        {protocolVersionsList.includes(
                          item?.metered_load?.protocol_version
                        )
                          ? protocolVersionV4 === item?.metered_load?.protocol_version ? item?.translated_data?.tractor?.to : item?.translated_data?.primary_trailer?.to
                          : item?.translated_data?.driver_id?.to ?? "-"}
                      </span>
                    </p>
                    <p className="order-details-tooltip-content-list-item-root">
                      <label className="order-details-tooltip-content-list-item-key">
                        Success:{" "}
                      </label>
                      <span className="order-details-tooltip-content-list-item-value error">
                        {protocolVersionsList.includes(item?.metered_load?.protocol_version)
                          ? protocolVersionV4 === item?.metered_load?.protocol_version
                            ? String(item?.translated_data?.tractor?.success ?? "")
                            : String(item?.translated_data?.primary_trailer?.success ?? "")
                          : String(item?.translated_data?.driver_id?.success ?? "")}

                      </span>
                    </p>
                  </li>
                </ul>
              </div>

              <div className="content-main-block">
                <label className="order-details-tooltip-content-title">
                  {protocolVersionsList.includes(
                    item?.metered_load?.protocol_version
                  )
                    ? "Supplier Id"
                    : "Shipper Id"}
                </label>
                <ul className="order-details-tooltip-content-list">
                  <li className="order-details-tooltip-content-list-item">
                    <p className="order-details-tooltip-content-list-item-root">
                      <label className="order-details-tooltip-content-list-item-key">
                        From:{" "}
                      </label>
                      <span className="order-details-tooltip-content-list-item-value">
                        {protocolVersionsList.includes(
                          item?.metered_load?.protocol_version
                        )
                          ? item?.translated_data?.supplier_id?.from
                          : item?.translated_data?.shipper_id?.from ?? "-"}
                      </span>
                    </p>
                    <p className="order-details-tooltip-content-list-item-root">
                      <label className="order-details-tooltip-content-list-item-key">
                        To:{" "}
                      </label>
                      <span className="order-details-tooltip-content-list-item-value">
                        {protocolVersionsList.includes(
                          item?.metered_load?.protocol_version
                        )
                          ? item?.translated_data?.supplier_id?.to
                          : item?.translated_data?.shipper_id?.to ?? "-"}
                      </span>
                    </p>
                    <p className="order-details-tooltip-content-list-item-root">
                      <label className="order-details-tooltip-content-list-item-key">
                        Success:{" "}
                      </label>
                      <span className="order-details-tooltip-content-list-item-value error">
                        {protocolVersionsList.includes(item?.metered_load?.protocol_version)
                          ? String(item?.translated_data?.supplier_id?.success ?? "")
                          : String(item?.translated_data?.shipper_id?.success ?? "")}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>

              {!protocolVersionsList.includes(
                item?.metered_load?.protocol_version
              ) && (
                  <div className="content-main-block">
                    <label className="order-details-tooltip-content-title">
                      Customer Id
                    </label>
                    <ul className="order-details-tooltip-content-list">
                      <li className="order-details-tooltip-content-list-item">
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            From:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value">
                            {item?.translated_data?.customer_id?.from ?? "-"}
                          </span>
                        </p>
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            To:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value">
                            {item?.translated_data?.customer_id?.to ?? "-"}
                          </span>
                        </p>
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            Error:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value error">
                            {item?.translated_data?.customer_id?.error ?? ""}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                )}

              {protocolVersionsList.includes(
                item?.metered_load?.protocol_version
              ) && (
                  <div className="content-main-block">
                    <label className="order-details-tooltip-content-title">
                      Loading Number
                    </label>
                    <ul className="order-details-tooltip-content-list">
                      <li className="order-details-tooltip-content-list-item">
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            From:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value">
                            {item?.translated_data?.loading_number?.from ?? "-"}
                          </span>
                        </p>
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            To:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value">
                            {item?.translated_data?.loading_number?.to ?? "-"}
                          </span>
                        </p>
                        <p className="order-details-tooltip-content-list-item-root">
                          <label className="order-details-tooltip-content-list-item-key">
                            Success:{" "}
                          </label>
                          <span className="order-details-tooltip-content-list-item-value error">
                            {String(item?.translated_data?.loading_number?.success ?? "")}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                )}

              {item?.translated_data?.product_code &&
                item?.translated_data?.product_code.length ? (
                <div className="content-main-block">
                  <label className="order-details-tooltip-content-title">
                    Product Codes
                  </label>
                  <ul className="order-details-tooltip-content-list">
                    {item?.translated_data?.product_code.map(
                      (productCode, index) => {
                        return (
                          <li
                            key={index}
                            className="order-details-tooltip-content-list-item"
                          >
                            <p className="order-details-tooltip-content-list-item-root">
                              <label className="order-details-tooltip-content-list-item-key">
                                From:{" "}
                              </label>
                              <span className="order-details-tooltip-content-list-item-value">
                                {productCode.from || "-"}
                              </span>
                            </p>
                            <p className="order-details-tooltip-content-list-item-root">
                              <label className="order-details-tooltip-content-list-item-key">
                                To:{" "}
                              </label>
                              <span className="order-details-tooltip-content-list-item-value">
                                {productCode.to || "-"}
                              </span>
                            </p>
                            <p className="order-details-tooltip-content-list-item-root">
                              <label className="order-details-tooltip-content-list-item-key">
                                Success:{" "}
                              </label>
                              <span className="order-details-tooltip-content-list-item-value error">
                                {`${String(productCode?.success ?? "")}`}
                              </span>
                            </p>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              ) : null}
            </div>
          ) : (
            <label>No Data Found</label>
          )}
        </div>
      </div>
      {showJson &&
        user &&
        user.attributes["custom:role"] === "superadmin" &&
        item.inbound_payload_data && (
          <>
            <div className="break-line"></div>
            <div className="content-right content-right-block">
              <div className="input-details">
                <div className='copy-details-root-orders'>
                  <label className="input-details-title">Inbound JSON</label>
                  <CopyToClipboard
                    text={JSON.stringify(item.inbound_payload_data, null, 2)}
                    onCopy={() =>
                      setCopyState({
                        terminal_order_details: false,
                        inbound_payload_data: true,
                      })
                    }
                  >
                    <img
                      src={CopyIcon}
                      className={copyState.inbound_payload_data ? "active-copy" : ""}
                      title="Copy Inbound JSON"
                      alt="Copy Inbound JSON"
                    />
                  </CopyToClipboard>
                </div>

                <div className="input-inner-content">
                  <pre>
                    {JSON.stringify(item.inbound_payload_data, null, 2)}
                  </pre>
                </div>
              </div>
            </div>
          </>
        )}
      {showJson &&
        user &&
        user.attributes["custom:role"] === "superadmin" &&
        item?.terminal_order_details && (
          <>
            <div className="break-line"></div>
            <div className="content-right content-right-block">
              <div className="input-details">
                <div className='copy-details-root-orders'>
                  <label className="input-details-title">Output JSON</label>
                  <CopyToClipboard
                    text={JSON.stringify(item.terminal_order_details, null, 2)}
                    onCopy={() =>
                      setCopyState({
                        terminal_order_details: true,
                        inbound_payload_data: false,
                      })
                    }
                  >
                    <img
                      src={CopyIcon}
                      className={copyState.terminal_order_details ? "active-copy" : ""}
                      title="Copy Outbound JSON"
                      alt="Copy Outbound JSON"
                    />
                  </CopyToClipboard>
                </div>
                <div className="input-inner-content">
                  <pre>
                    {JSON.stringify(item.terminal_order_details, null, 2)}
                  </pre>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );

  const orderStatusTooltipContent = (
    <div>
      <span className="order-details-tooltip-content-status">
        Previous Status:
      </span>{" "}
      {item.previous_order_status}
    </div>
  );

  return (
    <TableRow className={clsx("table-body-row", isOrdersTable && "orders-row")}>
      {headCells.map((headCell) => {
        if (headCell.id === "replay") {
          const value = _.get(item, "order_status");
          return (
            <TableCell
              key={headCell.id}
              width={headCell.width}
              align={headCell.align}
              className={isOrdersTable ? "orders-cell" : ""}
            >
              {value === ORDER_STATUS.tandemError ||
                value === ORDER_STATUS.tandemAlert ||
                value === ORDER_STATUS.crossRefError ||
                value === ORDER_STATUS.holdingExpDate ||
                value === ORDER_STATUS.holding ||
                value === ORDER_STATUS.terminalError ||
                value === ORDER_STATUS.terminalApiRejected ||
                value === ORDER_STATUS.updateXRefError ? (
                <button
                  className="btn warning retry-btn"
                  onClick={() => onReplyRowClick(item._id)}
                >
                  Replay
                </button>
              ) : (
                ""
              )}
            </TableCell>
          );
        }

        if (
          headCell.id === "created_date" ||
          headCell.id === "order_update_token_expired_at" ||
          headCell.id === "metered_load.effective_date"
        ) {
          const value = _.get(item, headCell.accessor);
          return (
            <ElipsisCell
              key={headCell.id}
              width={headCell.width}
              align={headCell.align}
              text={value ? dateFormat(value) : ""}
              className={isOrdersTable ? "orders-cell" : ""}
            />
          );
        }

        if (headCell.id === "order_status") {
          const value = _.get(item, headCell.accessor);
          return (
            <TableCell
              key={headCell.id}
              width={headCell.width}
              align={headCell.align}
              className={isOrdersTable ? "orders-cell" : ""}
            >
              <div className="status-chip">
                <label
                  className={clsx(
                    "label-text",
                    !value && "primary",
                    value === ORDER_STATUS.new && "complementory",
                    value === ORDER_STATUS.processing && "processing",
                    value === ORDER_STATUS.holding && "holding",
                    value === ORDER_STATUS.queuedForTerminal &&
                    "queuedForTerminal",
                    value === ORDER_STATUS.sentToTerminal && "sentToTerminal",
                    value === ORDER_STATUS.holdingExpDate && "holdingExpDate",
                    value === ORDER_STATUS.completed && "completed",
                    value === ORDER_STATUS.success && "success",
                    value === ORDER_STATUS.terminalAccepted && "success",
                    value === ORDER_STATUS.error && "error",
                    value === ORDER_STATUS.tandemError && "error",
                    value === ORDER_STATUS.tandemAlert && "error",
                    value === ORDER_STATUS.crossRefError && "error",
                    value === ORDER_STATUS.updateXRefError && "error",
                    value === ORDER_STATUS.terminalError && "error",
                    value === ORDER_STATUS.terminalApiRejected && "error",
                    value === ORDER_STATUS.expired && "expired",
                    value ===
                    (ORDER_STATUS.cancelled || ORDER_STATUS.cancelling) && ""
                  )}
                >
                  {value || "No Status"}
                </label>
                {(value === ORDER_STATUS.error ||
                  value === ORDER_STATUS.tandemError ||
                  value === ORDER_STATUS.tandemAlert ||
                  value === ORDER_STATUS.terminalError ||
                  value === ORDER_STATUS.terminalApiRejected) && (
                    <Tooltip
                      title={item.processing_failing_reason}
                      arrow
                      classes={{
                        tooltip: "tooltip",
                      }}
                    >
                      <InfoIcon className="table-info-icon" />
                    </Tooltip>
                  )}
                {value === ORDER_STATUS.expired &&
                  item.previous_order_status && (
                    <Tooltip
                      title={orderStatusTooltipContent}
                      arrow
                      classes={{
                        tooltip: "tooltip",
                      }}
                    >
                      <InfoIcon className="table-info-icon" />
                    </Tooltip>
                  )}
              </div>
            </TableCell>
          );
        }

        if (headCell.id === "metered_load.primary_trailer") {
          return (
            <ElipsisCell
              key={headCell.id}
              width={headCell.width}
              align={headCell.align}
              text={item?.metered_load?.protocol_version === protocolVersionV4 ? item?.metered_load?.tractor : _.get(item, headCell.accessor)}
              className={isOrdersTable ? "orders-cell" : ""}
            />
          )
        }

        if (headCell.id === "details") {
          return (
            <TableCell
              key={headCell.id}
              width={headCell.width}
              align={headCell.align}
              className={isOrdersTable ? "orders-cell" : ""}
            >
              <Tooltip
                title={tooltipContent}
                arrow
                placement="left"
                classes={{
                  tooltip: "tooltip",
                }}
              >
                <InfoIcon className="table-info-icon" />
              </Tooltip>
            </TableCell>
          );
        }

        return (
          <ElipsisCell
            key={headCell.id}
            width={headCell.width}
            align={headCell.align}
            text={_.get(item, headCell.accessor)}
            className={isOrdersTable ? "orders-cell" : ""}
          />
        );
      })}
    </TableRow>
  );
};

export default Row;
